<template>
  <router-link class="announcement-item" :to="{ name: $options.ANNOUNCEMENTS_DETAILED, params: { id: itemId } }">
    <v-img v-if="!media.isMobile" :src="cacheImageURI" :aspect-ratio="aspectRatio" width="100%" />
    <div class="d-flex align-center px-4 px-md-0 mt-4">
      <h2
        class="announcement-item__title flex-grow-1 text-md-h6 text-md-h5 font-weight-medium"
        :class="{ 'announcement-item__title--with-actions': canDisplayActions }"
      >
        {{ title }}
      </h2>
      <time
        v-if="!canDisplayActions || !media.isMobile"
        class="announcement-item__time text-body-2 flex-shrink-0"
        :datetime="dispatchTime"
        >{{ normalizedDate }}</time
      >
    </div>
    <time
      v-if="canDisplayActions && media.isMobile"
      class="announcement-item__time mt-1 text-body-2 flex-shrink-0"
      :class="{ 'px-4': media.isMobile }"
      :datetime="dispatchTime"
      >{{ normalizedDate }}</time
    >
    <div v-if="recipientList" class="px-4 px-md-0 announcement-item__recipients mt-1 text-caption text--secondary">
      {{ textRecipientList }}
    </div>
    <div class="mb-4 px-4 mb-md-0 px-md-0 mt-4 text-body-2 announcement-item__body">{{ content }}</div>

    <div v-if="canDisplayActions" class="announcement-item__actions">
      <icon-button
        class="announcement-item__button mr-2"
        color="primary"
        :to="{ name: $options.ANNOUNCEMENTS_EDIT, params: { id: itemId } }"
        @click.stop
      >
        <v-icon color="white">mdi-pencil</v-icon>
      </icon-button>

      <!-- Пришлось использовать click.prevent, т.к. click.stop по какой-то причине не работает -->
      <icon-button class="announcement-item__button" color="primary" @click.prevent @click="remove">
        <v-icon color="white">mdi-delete</v-icon>
      </icon-button>
    </div>
  </router-link>
</template>

<script>
// node_modules
import { format } from 'date-fns';

// Utils
import { translateDate } from '@/utils/dateFormatting';

// Constants
import { ANNOUNCEMENTS_DETAILED, ANNOUNCEMENTS_EDIT } from '@/constants/routes';
import { UPDATE } from '@/constants/actions';
import { ANNOUNCEMENTS } from '@/constants/subjects';

// Components
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'AnnouncementItem',

  components: { IconButton },

  props: {
    image: { type: String, required: true },
    title: { type: String, required: true },
    recipientList: { type: Array, default: () => [] },
    content: { type: String, required: true },
    itemId: { type: Number, required: true },
    dispatchTime: { type: String, required: true },
    isScheduled: { type: Boolean, default: false },
    isDrafts: { type: Boolean, default: false },
  },

  inject: ['media'],

  data() {
    return {
      cacheImageURI: null,
    };
  },

  computed: {
    canUpdate() {
      return this.$can(UPDATE, ANNOUNCEMENTS);
    },

    canDisplayActions() {
      return (this.isScheduled || this.isDrafts) && this.canUpdate;
    },

    textRecipientList() {
      return this.recipientList.map(recipient => `${recipient.firstName} ${recipient.lastName}`).join(', ');
    },

    aspectRatio() {
      if (this.media.isMobile) {
        return 38 / 15;
      }

      return 51 / 28;
    },

    date() {
      return new Date(this.dispatchTime);
    },

    normalizedDate() {
      return translateDate(format(this.date, 'd L yyyy'));
    },

    normalizedTime() {
      return format(this.date, 'HH:mm');
    },
  },

  watch: {
    image: {
      handler(newValue) {
        if (!this.cacheImageURI) this.cacheImageURI = newValue;
      },
      immediate: true,
    },
  },

  methods: {
    remove() {
      this.$emit('remove', this.itemId);
    },
  },

  ANNOUNCEMENTS_DETAILED,
  ANNOUNCEMENTS_EDIT,
};
</script>

<style lang="scss">
.announcement-item {
  text-decoration: none;
  color: inherit !important;
  display: block;
  overflow: hidden;
  position: relative;

  &__title {
    white-space: nowrap;
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      width: 140px;
      height: 100%;
      position: absolute;
      background: linear-gradient(90deg, #ffffff00 0%, #ffffff 100%);
      right: 0;
      top: 0;
    }

    &--with-actions::before {
      right: 96px !important;
    }
  }

  @media (max-width: map.get($--screens, 'sm')) {
    box-shadow: 0px 4px 10px rgba(0, 47, 255, 0.17);
    border-radius: 4px;
  }

  &__recipients {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__body {
    opacity: 0.75;
    max-height: 160px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
    overflow: hidden;

    @media (max-width: map.get($--screens, 'sm')) {
      max-height: 120px;
      -webkit-line-clamp: 6;
    }
  }

  &__actions {
    position: absolute;
    top: 16px;
    right: 16px;

    background-color: transparent;

    @media (max-width: map.get($--screens, 'sm')) {
      background-color: $--white-color-0;
    }
  }

  &__button {
    width: 44px !important;
    min-width: 44px !important;
    height: 44px !important;
    min-height: 44px !important;
  }
}
</style>
