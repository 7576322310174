<template>
  <v-row no-gutters class="align-end flex-column-reverse flex-md-row">
    <v-col cols="12" md="8">
      <search-bar v-model="searchModel" :placeholder="$t('announcements.search')" single-line full-width hide-details />
    </v-col>

    <v-col cols="12" md="4" class="pl-0 pl-md-6">
      <mobile-sort :reverse="media.isMobile" :sort-list="sortList" :sort-by="activeItem" :options.sync="options">
        <slot></slot>
      </mobile-sort>
    </v-col>
  </v-row>
</template>

<script>
// Utils
import { camelToSnake } from '@/utils/formatters';

// Components
import SearchBar from '@/components/SearchBar.vue';
import MobileSort from '@/components/MobileSort/index.vue';

export default {
  name: 'AnnouncementPanel',

  inject: ['media'],

  components: { SearchBar, MobileSort },

  props: {
    search: { type: String, default: '' },
  },

  data() {
    return {
      options: {
        sortBy: '',
        sortDesc: null,
      },
    };
  },

  computed: {
    searchModel: {
      get() {
        return this.search;
      },

      set(search) {
        this.$emit('update:search', search);
      },
    },

    sortList() {
      return [
        { text: this.$t('announcement.dispatch_time'), value: 'dispatchTime' },
        { text: this.$t('announcement.title'), value: 'title' },
      ];
    },

    orderBy() {
      const { sortBy, sortDesc } = this.options;
      const isNotSetSortDesc = sortDesc === undefined;

      if (!sortBy || isNotSetSortDesc) return undefined;

      const value = camelToSnake(this.activeItem?.sortValue || sortBy);

      return sortDesc ? `-${value}` : value;
    },

    activeItem() {
      return this.sortList.find(
        sortItem => sortItem.value === this.options.sortBy || sortItem?.sortValue === this.options.sortBy
      );
    },
  },

  watch: {
    orderBy(newValue) {
      this.$emit('update:order-by', newValue);
    },
  },
};
</script>

<style lang="scss">
.announcement-panel {
  &__limiter {
    width: 358px;

    @media (max-width: map.get($--screens, 'sm')) {
      width: 100%;
    }
  }
}
</style>
