<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <icon-button v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-horizontal</v-icon>
      </icon-button>
    </template>

    <v-list>
      <v-list-item v-if="canUpdate" :to="createAnnouncementsRoute">
        <v-list-item-title>
          <v-icon right>mdi-plus</v-icon>
          {{ $t('button.add') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="exportAnnouncement">
        <v-list-item-title>
          <v-icon right>mdi-file-download</v-icon>
          {{ $t('button.export') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import IconButton from '@/components/IconButton.vue';

import { ANNOUNCEMENTS_CREATE } from '@/constants/routes';

export default {
  name: 'AnnouncementsMobileMenu',
  components: { IconButton },
  props: {
    canUpdate: { type: Boolean, required: true },
  },
  computed: {
    createAnnouncementsRoute() {
      return { name: ANNOUNCEMENTS_CREATE };
    },
  },

  methods: {
    exportAnnouncement() {
      this.$emit('export-announcement');
    },
  },
};
</script>
