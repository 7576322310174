<template>
  <v-row no-gutters class="mx-0 mx-md-n3">
    <v-col v-for="item in items" :key="item.id" class="px-0 px-md-3 mb-6 mb-md-10" :cols="12" :md="4">
      <slot
        :title="item.title"
        :image="getItemImage(item)"
        :clients="item.clients"
        :content="item.content"
        :item-id="item.id"
        :dispatch-time="item.dispatchTime"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AnnouncementList',

  props: {
    items: { type: Array, default: () => [] },
  },

  methods: {
    getItemImage(item) {
      return item.defaultImage?.url || item.image?.url;
    },
  },
};
</script>
