<template>
  <base-missing-data :icon="$options.IconMissingDataAnnouncements" class="announcement-missing-data" :message="message">
    <template v-if="!isSent" #actions>
      <v-btn large color="primary" :to="{ name: $options.ANNOUNCEMENTS_CREATE }" exact>
        {{ $t('announcements.new_announcement') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </template>
  </base-missing-data>
</template>

<script>
// Components
import BaseMissingData from '@/components/BaseMissingData.vue';

import { SENT, SCHEDULED, DRAFTS } from '@/constants/entityStatuses';
import { ANNOUNCEMENTS_CREATE } from '@/constants/routes';

import IconMissingDataAnnouncements from '@/components/MissingDataIcons/MissingDataAnnouncements.vue';

export default {
  name: 'AnnouncementMissingData',

  components: { BaseMissingData },

  props: {
    status: { type: String, required: true },
  },

  computed: {
    isSent() {
      return this.status === SENT;
    },

    statusTranslations() {
      return {
        [SENT]: this.$t('announcements.sent'),
        [SCHEDULED]: this.$t('announcements.scheduled'),
        [DRAFTS]: this.$t('announcements.drafts'),
      };
    },

    currentStatusText() {
      return this.statusTranslations[this.status].toLowerCase();
    },

    message() {
      return this.$t('announcement.no_announcements', { status: this.currentStatusText });
    },
  },

  IconMissingDataAnnouncements,

  ANNOUNCEMENTS_CREATE,
};
</script>
