/**
 * Не удалось найти возможность управлять подобным в vuetify
 * Вынес в утилку
 */

export function showScroll() {
  document.documentElement.classList.remove('overflow-y-hidden');
}

export function hideScroll() {
  document.documentElement.classList.add('overflow-y-hidden');
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}
